input[type='number']
  -moz-appearance: textfield

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button
  -webkit-appearance: none

.reset [type="number"]
  -moz-appearance: spinner-textfield

.reset input[type='number']::-webkit-outer-spin-button,
.reset input[type='number']::-webkit-inner-spin-button
  -webkit-appearance: spinner-textfield

input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button,
input[type=password]::-webkit-caps-lock-indicator
  visibility: hidden
  display: none !important
  pointer-events: none
  height: 0
  width: 0
  margin: 0

input,
textarea,
button,
select,
a
  -webkit-tap-highlight-color: rgba(0,0,0,0)

a
  text-decoration: none
