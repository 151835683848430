/**
 * Colors
 */

$primary-black: #212121
$accent: #de2600
$error: #e02020

$white: #fff
$black: #000

$grey-1: #fafafa
$grey-2: #eaeaea
$grey-3: #c5c5c5
$grey-4: #8d8d8d

$mpy-grey: #F5F5FA
$mpy-accent: #6532EF
$mpy-purple: #FF67B0
$mpy-error: #FF67B0

/**
 * Fonts
 */
$main-font: -apple-system, BlinkMacSystemFont, "Segoe UI",  Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif
