*
  padding: 0
  margin: 0
  box-sizing: border-box

@import 'reset'
@import 'variables'
@import 'libs'

body, html, #root
  display: flex
  flex-direction: column
  height: 100%
  height: calc(var(--vh, 1%) * 100)
  min-height: 100%
  font-family: $main-font
  font-size: 14px
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

@media (min-width: 768px)
  .grey-bg
    background-color: #f5f5f5

.white-bg
  background-color: var(--white)

.EmojiPickerReact
  background: transparent !important

.EmojiPickerReact.epr-main
  border: none !important

.EmojiPickerReact .epr-header
  display: none
